/* eslint-disable no-empty */
import React from 'react';

const useQuery = (url, call) => {
  const [apiData, setApiData] = React.useState();

  const fetchData = async() => {
    try{
      let res = await fetch(url);
      res = await res.text();
      setApiData(res)
    } catch (err){
      console.log(err);
    }
  }

  React.useEffect(() => {
    fetchData();
  }, [url, call]);

  return apiData;
};

export default useQuery;