import "./App.css";
import useQuery from "./hooks/useQuery";
import { ShowCodeBlock } from "./ShowCodeBlock";
import ShowParsedData from "./ShowParsedData";

function App() {

  const data =(useQuery(
    new URLSearchParams(document.location.search).get("file_path")
  ));

const isDebug=new URLSearchParams(document.location.search).get("format")
  
 
  const theme=new URLSearchParams(document.location.search).get("theme")

  


  if (!data) {
    return <div>Loading...</div>;
  }
    
  return (
    
    <div className={theme==='light'?"App":"darkApp"}>
    
  
      {isDebug=== "debug" ? (
        <>
            <ShowParsedData data={data} />
        </>
      ) : (
        <ShowCodeBlock data={data ? data : ""} theme={theme} />
      )}
    </div>
  );
}

export default App;
