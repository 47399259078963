import React from 'react'
import style from './fetch.module.scss'
const ShowParsedData = ({data }) => {
  const parsedData = JSON.parse(data)
  const mapdata = parsedData.curl;


  return (
    <>
    {mapdata.map((elem, i) => {
      return  <main key={i} className={style.Wrapper}>
      <div className={style.tmcontainer}>
        
     <span  className={style.time}>   {elem.timestamp} :</span> <span  className={style.message}>{elem.message}</span> </div>
      
      

    </main>

      

    })}
    </>


  )
}

export default ShowParsedData