import { CopyBlock, a11yLight } from "react-code-blocks";
import { dracula } from "react-code-blocks";

export const ShowCodeBlock = ({ isJson, data ,theme }) => {
  const style = {
    width: "100%"  
  };


  return (
    <CopyBlock
      language={"html"}
     text={(data)}
      showLineNumbers
      theme={theme==='light'?a11yLight:dracula}
      wrapLines
      customStyle={style}
    />
  );
};
